<template lang="pug">
.edit-matrix__body
  v-dropdown(:focusSwitchEL="focusSearch")
    v-input-search.input-search(
      :placeholder="'ui.inputs.type_to_search_employees_departments_locations' | translate",
      type="text",
      :auto-focus="false",
      @focus="focusSearch = $event",
      v-model="search"
    )
    template(slot="content")
      v-card(elevation)
        v-scrollable(
          updateble,
          :min-height="20",
          :bottom-offset="15",
          @scroll-complete="onNextPage"
        )
          template(v-if="loaded")
            template(v-if="hybridSearchData.length")
              v-list
                v-list-item(
                  v-for="item in hybridSearchData",
                  :key="item.id",
                  @click="!selectedUsers.find((el) => el.item.id === item.item.id && item.typeId === el.typeId) ? addItem(item) : removeItem(item)"
                )
                  slot(name="icon")
                    v-avatar(
                      v-if="item.typeId === searchTypes.EMPLOYEE",
                      :size="48",
                      :src="item.item.profilePictureUrl"
                    )
                    .avatar(v-else)
                      icon-department.m-0(
                        v-if="item.typeId === searchTypes.DEPARTMENT"
                      )
                      icon-location.m-0(v-else)
                  v-list-item-content
                    v-list-item-title
                      span {{ item.typeId === searchTypes.EMPLOYEE ? item.item.firstName + ' ' + item.item.lastName : item.item.name }}
                    v-list-item-subtitle(
                      v-if="(item.item.position && item.item.position.name) || (item.item.department && item.item.department.name)"
                    )
                      span {{ (item.item.position && item.item.position.name) || (item.item.department && item.item.department.name) }}
                  v-list-item-actions
                    v-btn(
                      v-if="!selectedUsers.find((el) => el.item.id === item.item.id && item.typeId === el.typeId)",
                      icon,
                      success
                    )
                      icon-plus
                    v-btn(v-else, icon, danger)
                      icon-minus

            .search-result__empty(v-else)
              v-list-empty(v-if="search.length && !loadingSearch")
                i.icon.icon--sm.icon--no-result
                span {{ 'ui.labels.no_results' | translate }}
              employee-item-skeleton(v-else-if="loadingSearch", :count="3")
              v-list-empty(v-else)
                i.icon.icon--sm.icon--empty
                span {{ 'ui.labels.list_is_empty' | translate }}
          employee-item-skeleton(v-else, :count="3")
          employee-item-skeleton(small, :count="skeletons")
  .edit-matrix__content(v-if="!loaded")
    .edit-matrix__baguettes
      v-skeleton(width="172px")
      v-skeleton(width="172px")
      v-skeleton(width="172px")
  v-scrollable.edit-matrix__content(
    v-else-if="selectedUsers.length",
    updateble,
    :min-height="20"
  )
    .edit-matrix__baguettes
      v-baguette(
        v-for="(item, index) in selectedUsers",
        :key="index + 'e'",
        :color="item.color",
        :background-color="item.bg"
      )
        icon-employees.m-0(v-if="item.typeId === searchTypes.EMPLOYEE")
        icon-department.m-0(v-else-if="item.typeId === searchTypes.DEPARTMENT")
        icon-location.m-0(v-else)
        span {{ item.typeId === searchTypes.EMPLOYEE ? item.item.firstName + ' ' + item.item.lastName : item.item.name }}
        v-btn(paddingless, text, @click="removeItem(item)")
          icon-close.m-0
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import scrollPaginationSkeletonsMixin from '@/mixins/scroll-pagination-skeletons.mixin.js'
import EmployeeItemSkeleton from '@/components/common/lists/EmployeeItemSkeleton'
import { HYBRID_SEARCH_TYPES } from '@/util/constants'
import IconPlus from '@/components/svg/IconPlusAddToList.vue'
import IconMinus from '@/components/svg/icons/IconMinus.vue'
import IconClose from '@/components/svg/VInputSearch_CloseIcon'
import IconEmployees from '@/components/svg/skill-matrix/IconEmployees.vue'
import IconLocation from '@/components/svg/skill-matrix/IconLocation.vue'
import IconDepartment from '@/components/svg/skill-matrix/IconDepartment.vue'
const DESC_EMPLOYEE = {
  typeId: HYBRID_SEARCH_TYPES.EMPLOYEE,
  color: '#29A687',
  bg: '#E3F6F1'
}
const DESC_DEPARTMENT = {
  typeId: HYBRID_SEARCH_TYPES.DEPARTMENT,
  color: '#BB6BD9',
  bg: '#F4E7F9'
}
const DESC_LOCATION = {
  typeId: HYBRID_SEARCH_TYPES.LOCATION,
  color: '#0082C0',
  bg: '#D7EBF4'
}
export default {
  name: 'SelectEmployeesStep',
  components: {
    EmployeeItemSkeleton,
    IconPlus,
    IconMinus,
    IconClose,
    IconEmployees,
    IconLocation,
    IconDepartment
  },
  mixins: [scrollPaginationSkeletonsMixin],
  props: {
    matrix: Object
  },

  data: () => ({
    selectedUsers: [],
    search: '',
    loaded: false,
    loadingSearch: true,
    focusSearch: false
  }),

  computed: {
    ...mapGetters('search', ['hybridSearchData', 'hybridPaginationParams']),

    searchTypes () {
      return HYBRID_SEARCH_TYPES
    }
  },

  async mounted () {
    this.loadSelectedEmployees()
    await this.hybridSearch({ params: { noBadges: 1, noPositions: 1 } })
    this.initScrollPagination(
      { pagination: this.hybridPaginationParams },
      this.loadNextPage
    )
    this.loadingSearch = false
    this.loaded = true
  },
  methods: {
    ...mapActions('search', ['hybridSearch']),

    ...mapActions('skillMatrix', [
      'addEmployee',
      'addDepartment',
      'addLocation',
      'removeEmployee',
      'removeDepartment',
      'removeLocation',
      'getEmployees',
      'getDepartment',
      'getLocation'
    ]),

    addItem (item) {
      switch (item.typeId) {
        case this.searchTypes.EMPLOYEE:
          this.addEmployee({ matrixID: this.matrix.id, ids: [item.item.id] })
          this.selectedUsers.push({
            ...item,
            ...DESC_EMPLOYEE
          })
          break
        case this.searchTypes.DEPARTMENT:
          this.addDepartment({ matrixID: this.matrix.id, ids: [item.item.id] })
          this.selectedUsers.push({
            ...item,
            ...DESC_DEPARTMENT
          })
          break
        case this.searchTypes.LOCATION:
          this.addLocation({ matrixID: this.matrix.id, ids: [item.item.id] })
          this.selectedUsers.push({
            ...item,
            ...DESC_LOCATION
          })
          break
      }
    },

    removeItem (item) {
      const index = this.selectedUsers.findIndex(
        (el) => el.item.id === item.item.id && item.typeId === el.typeId
      )
      this.selectedUsers.splice(index, 1)
      switch (item.typeId) {
        case this.searchTypes.EMPLOYEE:
          this.removeEmployee({
            matrixID: this.matrix.id,
            ids: [item.item.id]
          })
          break
        case this.searchTypes.DEPARTMENT:
          this.removeDepartment({
            matrixID: this.matrix.id,
            ids: [item.item.id]
          })
          break
        case this.searchTypes.LOCATION:
          this.removeLocation({
            matrixID: this.matrix.id,
            ids: [item.item.id]
          })
          break
      }
    },

    async loadSelectedEmployees () {
      const { data: dataEmployees } = await this.getEmployees(this.matrix.id)
      const { data: dataDepartment } = await this.getDepartment(this.matrix.id)
      const { data: dataLocation } = await this.getLocation(this.matrix.id)

      let arrEmployees = dataEmployees.map((item) => ({
        item,
        ...DESC_EMPLOYEE
      }))
      let arrDepartment = dataDepartment.map((item) => ({
        item,
        ...DESC_DEPARTMENT
      }))
      let arrLocation = dataLocation.map((item) => ({
        item,
        ...DESC_LOCATION
      }))

      this.selectedUsers = [...arrEmployees, ...arrDepartment, ...arrLocation]
    },

    async loadNextPage () {
      const page = this.hybridPaginationParams.page
        ? this.hybridPaginationParams.page + 1
        : 1
      const params = {
        page,
        noBadges: 1,
        noPositions: 1,
        noDeparemnets: 1,
        noLocations: 1
      }
      if (this.hybridPaginationParams.q) {
        params.q = this.hybridPaginationParams.q
      }
      await this.hybridSearch({ params, isPushed: true })
    },

    async onSearch (search) {
      this.loadingSearch = true
      const params = {
        q: search,
        page: 1,
        noBadges: 1,
        noPositions: 1
      }
      await this.hybridSearch({ params })
      this.loadingSearch = false
    }
  },

  watch: {
    search (value) {
      this.onSearch(value)
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-matrix {
  .v-card {
    padding: 8px 4px 8px 16px;
  }
  .v-list {
    padding-right: 20px;
  }
  .v-list__item {
    padding: 12px 8px 11px;
    .v-avatar {
      margin-right: 16px;
    }
    .avatar {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-right: 16px;
      background-color: $color-light-green;
      ::v-deep svg {
        color: $color-white;
        width: 24px;
        height: 24px;
      }
    }
    &-title,
    &-subtitle {
      font-size: 14px;
      line-height: 16px;
      color: $body-text;
    }
    &-title {
      font-weight: 700;
    }
    &-subtitle {
      font-weight: 400;
      margin-top: 4px;
    }
    ::v-deep .v-btn {
      width: 24px;
      height: 24px;
    }
  }

  &__content {
    flex: 1 0 auto;
    margin-top: 16px;
    max-height: 324px;
  }

  &__result {
    height: 100%;
    padding: 0;
  }
}
</style>
